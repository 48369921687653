<template>
  <div class="pageContol listWrap templateList renovation">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">APP装修</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">首页装修</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div class="df">
              <!-- <el-button  class="bor-bv" round @click="getData()"
                >返回</el-button
              > -->
            </div>
          </div>
          <div class="df searchbox">
            <el-button type="primary" class="bgc-bv" round @click="doSave"
              >发布</el-button
            >
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <div v-if="isok" class="flexdc" style="align-items: center">
              <img
                style="width: 414px"
                src="@/assets/app_home/app_top.png"
                alt=""
              />
              <div
                @mouseenter="modalShow(el.imgId)"
                @mouseleave="modalNone(el.imgId)"
                v-for="(el, index) in imgs"
                :key="index"
                class="flexdc"
                style="position: relative"
              >
                <img style="width: 414px" :src="el.url" alt="" />
                <div v-show="el.modal" class="modal df">
                  <div>
                    <img
                      v-if="index != 0"
                      src="@/assets/app_home/app_up.png"
                      alt=""
                      @click="doMove('up', index)"
                    />
                    <img
                      v-if="index !=imgs.length-1"
                      src="@/assets/app_home/app_down.png"
                      alt=""
                      @click="doMove('e', index)"
                    />
                    <img
                      @click="isBlock(el.imgId)"
                      v-if="el.block"
                      src="@/assets/app_home/app_block.png"
                      alt=""
                    />
                    <img
                      @click="isBlock(el.imgId)"
                      v-else
                      src="@/assets/app_home/app_none.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <img
                style="width: 414px"
                src="@/assets/app_home/app_footer.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "renovationEdit",
  components: {},
  data() {
    return {
      imgs: [
        {
          url: require("@/assets/app_home/app_home0.png"),
          imgId: "1",
          modal: false,
          block: true,
          sort: 0,
        },
        {
          url: require("@/assets/app_home/app_home1.png"),
          imgId: "2",
          modal: false,
          block: true,
          sort: 1,
        },
        {
          url: require("@/assets/app_home/app_home2.png"),
          imgId: "3",
          modal: false,
          block: true,
          sort: 2,
        },
        {
          url: require("@/assets/app_home/app_home3.png"),
          imgId: "4",
          modal: false,
          block: true,
          sort: 3,
        },
        {
          url: require("@/assets/app_home/app_home4.png"),
          imgId: "5",
          modal: false,
          block: true,
          sort: 4,
        },
        {
          url: require("@/assets/app_home/app_home5.png"),
          imgId: "6",
          modal: false,
          block: true,
          sort: 5,
        },
      ],
      stu: "add",
      isok:false,
    };
  },
  created() {
    // this.stu = this.$route.query.stu;
    this.stu = "edit";
    if (this.stu == "edit") {
      this.doQuery();
    }
  },
  computed: {},
  methods: {
    init() {},
    // 查询
    doQuery() {
      this.$post("/sys/homepage/v1/query/detail", {
        // id: this.$route.query.id,
        id: 0,
      }).then((res) => {
        // ;
        if (res.status == "0") {
          let data = res.data || [];
          data.forEach((el) => {
            this.imgs.forEach((item) => {
              if (el.name == item.imgId) {
                item.sort = el.sortNumber;
                item.block = !el.hidden;
              }
            });
          });
          this.imgs = this.imgs.sort((a, b) => a.sort - b.sort);
          this.isok = true;
        }
      });
    },
    doSave() {
      let arr = [];
      this.imgs.map((el,index) => {
        arr.push({
          hidden: !el.block,
          name: el.imgId,
          sortNumber: index,
        });
      });
      let params = {
        // areaId: "",
        items: arr,
      };

      if (this.stu == "edit") {
        // params.id = this.$route.query.id;
        params.id = 0;
      }
      this.$post("/sys/homepage/v1/modify", params).then((res) => {
        ;
        if (res.status == "0") {
          this.$message({
            type: "success",
            message: res.message,
          });
          this.doQuery();
        }
      });
    },
    modalShow(imgId) {
      // console.log(imgId);
      this.imgs.forEach((el) => {
        if (el.imgId == imgId) {
          el.modal = true;
        }
      });
    },
    modalNone(imgId) {
      // console.log(imgId);
      this.imgs.forEach((el, index) => {
        if (el.imgId == imgId) {
          el.modal = false;
          el.sort = index;
        }
      });
    },
    doMove(stu, ind) {
      if (stu == "up") {
        let temp = this.imgs[ind - 1];
        this.$set(this.imgs, ind - 1, this.imgs[ind]);
        this.$set(this.imgs, ind, temp);
      } else {
        let i = this.imgs[ind + 1];
        this.$set(this.imgs, ind + 1, this.imgs[ind]);
        this.$set(this.imgs, ind, i);
      }
      this.imgs.forEach((el) => {
        el.modal = false;
      });
    },
    isBlock(imgId) {
      this.imgs.forEach((el) => {
        if (el.imgId == imgId) {
          el.block = !el.block;
        }
      });
    },
  },
};
</script>
<style lang="less" scope>
.renovation {
  .modal {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(203, 209, 255, 0.4);
    z-index: 1;
    width: 90%;
    height: 100%;
    border: 2px dashed #001BFF;
    display: flex;
    justify-content: flex-end;
    div {
      align-self: flex-end;
      img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin: 0 5px;
      }
    }
  }
}
</style>

